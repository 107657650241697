import React from "react";
import Card from "react-bootstrap/Card";
import { FaUserAlt } from "react-icons/fa";
import { RiAdvertisementLine } from "react-icons/ri";
import logo from "./logo_smaller.png";
import MaxSupplyGraph from "./MaxSupplyGraph";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "center" }}>
            r/CryptoCurrency Moons is a pioneering Web3 project that tokenizes
            user subreddit karma and reputation
            <br />
            <br />
            Moons are used to vote on r/CryptoCurrency{" "}
            <a href="https://snapshot.org/#/cryptomods.eth" target="blank">
              governance proposals
            </a>{" "}
            that determine how the subreddit operates, and for special subreddit
            perks
            <br />
            <br />
            Moons are also the driving force behind a thriving{" "}
            <span className="main-name">
              ecosystem of users and advertisers
            </span>
            <br />
            <br />
            Companies buy and burn Moons to host AMAs, rent the subreddit
            banner, and more, on the world's largest cryptocurrency forum with{" "}
            <span className="main-name">8M community members</span>
            <br />
            <br />
            <Card
              className="project-card-view"
              style={{
                marginTop: "40px",
                padding: "20px 10px 10px 10px",
              }}
            >
              <div
                className="svg-container"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexFlow: "column",
                }}
              >
                <div style={{ fontSize: "16px", paddingBottom: "20px" }}>
                  Moons connect users and advertisers
                </div>
                <svg
                  className="cycle-diagram"
                  width="60%"
                  height="60%"
                  viewBox="0 0 300 300"
                  preserveAspectRatio="xMidYMid meet"
                >
                  {/* Lines connecting the circles with a gradient */}
                  <defs>
                    <linearGradient
                      id="lineGradient"
                      x1="0%"
                      y1="0%"
                      x2="100%"
                      y2="100%"
                    >
                      <stop offset="0%" stopColor="#cc3600" stopOpacity="1" />
                      <stop offset="100%" stopColor="#ff8c00" stopOpacity="1" />
                    </linearGradient>
                    <filter
                      id="dropShadow"
                      x="-20%"
                      y="-20%"
                      width="140%"
                      height="140%"
                    >
                      <feDropShadow
                        dx="0"
                        dy="0"
                        stdDeviation="3"
                        floodColor="#333333"
                      />
                    </filter>
                  </defs>
                  <line
                    x1="80"
                    y1="80"
                    x2="220"
                    y2="80"
                    stroke="url(#lineGradient)"
                    strokeWidth="3"
                    strokeDasharray="5 5"
                  />
                  <line
                    x1="80"
                    y1="80"
                    x2="150"
                    y2="220"
                    stroke="url(#lineGradient)"
                    strokeWidth="3"
                    strokeDasharray="5 5"
                  />
                  <line
                    x1="220"
                    y1="80"
                    x2="150"
                    y2="220"
                    stroke="url(#lineGradient)"
                    strokeWidth="3"
                    strokeDasharray="5 5"
                  />

                  {/* Circle icons with shadow */}
                  <g filter="url(#dropShadow)">
                    <foreignObject x="40" y="40" width="80" height="80">
                      <div className="circle-icon">
                        <FaUserAlt size={40} />
                      </div>
                    </foreignObject>
                    <foreignObject x="180" y="40" width="80" height="80">
                      <div className="circle-icon">
                        <RiAdvertisementLine size={40} />
                      </div>
                    </foreignObject>
                    <foreignObject x="110" y="180" width="80" height="80">
                      <div className="circle-icon">
                        {/*<FaCoins size={40} />*/}
                        <img src={logo} height={40} width={40} />
                      </div>
                    </foreignObject>
                  </g>

                  {/* Labels with a glow effect */}
                  <text
                    x="80"
                    y="30"
                    textAnchor="middle"
                    fontSize="16"
                    fill="whitesmoke"
                    filter="url(#glow)"
                  >
                    Users
                  </text>
                  <text
                    x="220"
                    y="30"
                    textAnchor="middle"
                    fontSize="16"
                    fill="whitesmoke"
                    filter="url(#glow)"
                  >
                    Advertisers
                  </text>
                  <text
                    x="150"
                    y="280"
                    textAnchor="middle"
                    fontSize="16"
                    fill="whitesmoke"
                    filter="url(#glow)"
                  >
                    Moons
                  </text>
                </svg>
              </div>
            </Card>
            <br />
            <br />
            Moons are <span className="main-name">deflationary</span>, since no
            new tokens can ever be created. Every time moons are burned the
            maximum supply decreases
            <Card
              className="project-card-view"
              style={{
                marginTop: "40px",
                padding: "20px 10px 80px 10px",
              }}
            >
              <MaxSupplyGraph />
            </Card>
            <br />
            <br />
            In the future, Moons purchased by advertisers may be redistributed
            to users based on their subreddit engagement, creating a{" "}
            <span className="main-name">value cycle </span>
            in the r/CryptoCurrency ecosystem
            <br />
            <br />
            Higher user engagement leads to increased demand for Moons from
            companies seeking advertisement space, thus increasing the number
            and value of Moons distributed to users, which in turn further
            boosts user engagement
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
