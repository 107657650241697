import React, { useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Particle from "../Particle";

export default function Constitution() {
  useEffect(() => {
    document.title = "CCMOON DAO Constitution";
  }, []);

  const constitutionUrl =
    "https://docs.google.com/document/d/120i6ExOyulpk31SbNqsLV69dNodCk7QSbm_JLx6llLE/edit?pli=1";

  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
            <span className="main-name">CCMOON</span> DAO Constitution
          </h1>
          <Col md={8} style={{ textAlign: "center" }}>
            <p style={{ fontSize: "1.2em", marginBottom: "20px" }}>
              You can read the CCMOON DAO Constitution by clicking the link
              below.
            </p>
            <Button
              variant="primary"
              size="lg"
              href={constitutionUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Constitution
            </Button>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
