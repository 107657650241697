import React from "react";
import Card from "react-bootstrap/Card";

function TradeCard(props) {
  return (
    <Card className="project-card-view">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Card.Img
          className="exchange-img"
          variant="top"
          src={props.imgPath}
          alt="card-img"
          style={{ height: "8em", width: "8em", alignItems: "center" }}
        />
      </div>
      <Card.Body>
        <Card.Title>
          <div style={{ display: "flex", flexFlow: "column" }}>
            <a className="a-exchange" href={props.link} target="_blank">
              <span>{props.title}</span>
            </a>
            <span style={{ fontSize: "0.75em" }}>{props.chain}</span>
          </div>
        </Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>
        {"\n"}
        {"\n"}
      </Card.Body>
    </Card>
  );
}
export default TradeCard;
