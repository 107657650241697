import React, { useState } from "react";
import { Container, Row, Form } from "react-bootstrap";
import Particle from "../Particle";

function Subscription() {
  const [selectedOption, setSelectedOption] = useState("arbitrumNovaMonthly");

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const iframes = {
    arbitrumNovaMonthly:
      "https://embed.ipfscdn.io/ipfs/bafybeicd3qfzelz4su7ng6n523virdsgobrc5pcbarhwqv3dj3drh645pi/?contract=0x6b0d0828cb059AAD9ee4e9CA13CdA15c19ada12d&chain=%7B%22name%22%3A%22Arbitrum+Nova%22%2C%22chain%22%3A%22ETH%22%2C%22rpc%22%3A%5B%22https%3A%2F%2F42170.rpc.thirdweb.com%2F%24%7BTHIRDWEB_API_KEY%7D%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22Ether%22%2C%22symbol%22%3A%22ETH%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22arb-nova%22%2C%22chainId%22%3A42170%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22arbitrum-nova%22%7D&clientId=be456757ff50937d966e1f278e1e20a1&theme=dark&primaryColor=purple",
    arbitrumNovaAnnual:
      "https://embed.ipfscdn.io/ipfs/bafybeicd3qfzelz4su7ng6n523virdsgobrc5pcbarhwqv3dj3drh645pi/?contract=0x4316CdC492Bf63DC2d18aBa728cA0ab048Eb3979&chain=%7B%22name%22%3A%22Arbitrum+Nova%22%2C%22chain%22%3A%22ETH%22%2C%22rpc%22%3A%5B%22https%3A%2F%2F42170.rpc.thirdweb.com%2F%24%7BTHIRDWEB_API_KEY%7D%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22Ether%22%2C%22symbol%22%3A%22ETH%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22arb-nova%22%2C%22chainId%22%3A42170%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22arbitrum-nova%22%7D&clientId=be456757ff50937d966e1f278e1e20a1&theme=dark&primaryColor=red",
    arbitrumOneMonthly:
      "https://embed.ipfscdn.io/ipfs/bafybeicd3qfzelz4su7ng6n523virdsgobrc5pcbarhwqv3dj3drh645pi/?contract=0xd6195B412D55B2958EA9b64522f5648ed6Dd2EC5&chain=%7B%22name%22%3A%22Arbitrum+One%22%2C%22chain%22%3A%22ETH%22%2C%22rpc%22%3A%5B%22https%3A%2F%2F42161.rpc.thirdweb.com%2F%24%7BTHIRDWEB_API_KEY%7D%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22Ether%22%2C%22symbol%22%3A%22ETH%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22arb1%22%2C%22chainId%22%3A42161%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22arbitrum%22%2C%22icon%22%3A%7B%22url%22%3A%22ipfs%3A%2F%2FQmcxZHpyJa8T4i63xqjPYrZ6tKrt55tZJpbXcjSDKuKaf9%2Farbitrum%2F512.png%22%2C%22width%22%3A512%2C%22height%22%3A512%2C%22format%22%3A%22png%22%7D%7D&clientId=be456757ff50937d966e1f278e1e20a1&theme=dark&primaryColor=cyan",
    arbitrumOneAnnual:
      "https://embed.ipfscdn.io/ipfs/bafybeicd3qfzelz4su7ng6n523virdsgobrc5pcbarhwqv3dj3drh645pi/?contract=0xD56960Aa3dDd896845072A6eB63E5C30D697629A&chain=%7B%22name%22%3A%22Arbitrum+One%22%2C%22chain%22%3A%22ETH%22%2C%22rpc%22%3A%5B%22https%3A%2F%2F42161.rpc.thirdweb.com%2F%24%7BTHIRDWEB_API_KEY%7D%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22Ether%22%2C%22symbol%22%3A%22ETH%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22arb1%22%2C%22chainId%22%3A42161%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22arbitrum%22%2C%22icon%22%3A%7B%22url%22%3A%22ipfs%3A%2F%2FQmcxZHpyJa8T4i63xqjPYrZ6tKrt55tZJpbXcjSDKuKaf9%2Farbitrum%2F512.png%22%2C%22width%22%3A512%2C%22height%22%3A512%2C%22format%22%3A%22png%22%7D%7D&clientId=be456757ff50937d966e1f278e1e20a1&theme=dark&primaryColor=blue",
  };

  return (
    <Container
      fluid
      className="project-section"
      style={{
        minHeight: "100vh",
        position: "relative !important",
        zIndex: 0,
      }}
    >
      <p style={{ color: "whitesmoke" }}>
        Read this{" "}
        <a
          href="https://np.reddit.com/r/CryptoCurrencyMoons/comments/1cn2s88/announcing_the_return_of_rcc_special_memberships/"
          target="_blank"
        >
          post
        </a>{" "}
        to learn more about r/CryptoCurrency Special Memberships!
      </p>
      <Form.Group
        controlId="subscriptionSelect"
        style={{ width: "75%", margin: "0 auto", textAlign: "center" }}
      >
        <Form.Label style={{ color: "whitesmoke" }}>
          Choose Subscription Type
        </Form.Label>
        <Form.Control
          as="select"
          value={selectedOption}
          onChange={handleChange}
        >
          <option value="arbitrumNovaMonthly">Arbitrum Nova (Monthly)</option>
          <option value="arbitrumNovaAnnual">Arbitrum Nova (Annual)</option>
          <option value="arbitrumOneMonthly">Arbitrum One (Monthly)</option>
          <option value="arbitrumOneAnnual">Arbitrum One (Annual)</option>
        </Form.Control>
      </Form.Group>

      <div
        style={{
          position: "relative",
          height: "600px",
          maxWidth: "100%",
        }}
      >
        <iframe
          src={iframes[selectedOption]}
          width="600px"
          height="600px"
          style={{ maxWidth: "100%" }}
          frameBorder="0"
        ></iframe>
      </div>
    </Container>
  );
}

export default Subscription;
