import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import MoonPriceChart from "./PriceChartMobile";
import CumulativeBalanceChart from "./MoonBurnsMobile";
import SecondaryStats from "./SecondaryStats";

function Home15() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container className="chart-container" style={{ paddingTop: "50px" }}>
        <Row>
          <Col>
            <SecondaryStats />
          </Col>
        </Row>
        <Row
          style={{ paddingTop: "20px", display: "flex", flexDirection: "row" }}
        >
          <Col md={6}>
            <MoonPriceChart />
          </Col>
          <Col md={6}>
            <CumulativeBalanceChart />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home15;
