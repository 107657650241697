import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import GuideCard from "./GuideCard";
import Particle from "../Particle";

function Guide() {
  return (
    <Container fluid className="project-section" style={{ minHeight: "100vh" }}>
      <Particle />
      <Container>
        <h1 className="project-heading text-center mb-5">
          Your Guide to the <strong className="main-name">MOON</strong>
        </h1>
        <Row className="justify-content-center">
          <Col md={4} sm={6} className="mb-4">
            <GuideCard
              title="Add MOONS to MetaMask"
              link="https://www.coingecko.com/learn/add-reddit-moons-to-metamask"
            />
          </Col>
          <Col md={4} sm={6} className="mb-4">
            <GuideCard
              title="Advertise on r/CryptoCurrency"
              link="https://np.reddit.com/r/CryptoCurrencyMeta/comments/1c7b10j/cc_ecosystem_buyers_guide_overview/"
            />
          </Col>
          <Col md={4} sm={6} className="mb-4">
            <GuideCard
              title="Governance Votes"
              link="https://snapshot.org/#/cryptomods.eth"
            />
          </Col>
          <Col md={4} sm={6} className="mb-4">
            <GuideCard
              title="Read the MOONS Wiki"
              link="https://reddit.com/r/CryptoCurrency/wiki/moons_wiki"
            />
          </Col>
          <Col md={4} sm={6} className="mb-4">
            <GuideCard
              title="Bridge Moons to Arbitrum One"
              link="https://np.reddit.com/r/CryptoCurrency/comments/1bjtzog/tutorial_bridging_moon_to_arbitrum_one_via_celer/"
            />
          </Col>
          <Col md={4} sm={6} className="mb-4">
            <GuideCard
              title="Message the Project Moderators"
              link="https://www.reddit.com/message/compose?to=%2Fr%2FCryptoCurrency"
            />
          </Col>
          {/* New card for "View the CCMOON DAO" */}
          <Col md={4} sm={6} className="mb-4">
            <GuideCard
              title="View the CCMOON DAO"
              link="https://docs.google.com/document/d/120i6ExOyulpk31SbNqsLV69dNodCk7QSbm_JLx6llLE/edit?pli=1"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Guide;
