import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const MoonPriceChart = () => {
  const [priceData, setPriceData] = useState([]);
  const [percentChange, setPercentChange] = useState(null);
  const [changeColor, setChangeColor] = useState(null);

  useEffect(() => {
    const fetchPriceData = async () => {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/coins/moon/market_chart?vs_currency=usd&days=90&interval=daily"
        );
        const data = response.data.prices;

        // Convert timestamp to date string and format price data
        const formattedData = data.map((item) => ({
          date: new Date(item[0]).toLocaleDateString(),
          price: parseFloat(item[1].toFixed(2)), // Round the price values to 2 decimal places
        }));

        setPriceData(formattedData);

        // Calculate percent change
        const firstPrice = formattedData[0].price;
        const lastPrice = formattedData[formattedData.length - 1].price;
        const change = lastPrice - firstPrice;
        const percentageChange = ((change / firstPrice) * 100).toFixed(2);
        setPercentChange(percentageChange);

        // Set color based on change
        if (change > 0) {
          setChangeColor("green");
        } else if (change < 0) {
          setChangeColor("red");
        } else {
          setChangeColor(null); // Handle zero change case
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPriceData();
  }, []);

  return (
    <ResponsiveContainer
      width="100%"
      aspect={3.5}
      margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
    >
      <h5
        style={{ textAlign: "center", color: "whitesmoke", paddingTop: "25px" }}
      >
        90-Day Moon Price{" "}
        {percentChange !== null && (
          <span>
            (
            <span style={{ color: changeColor }}>
              {percentChange > 0 ? "+" : ""}
              {Math.round(percentChange, 1)}%
            </span>
            )
          </span>
        )}
      </h5>
      <LineChart width={800} height={400} data={priceData}>
        <XAxis
          dataKey="date"
          tickLine={false}
          axisLine={false}
          stroke="whitesmoke"
          hide
        />
        <YAxis tickLine={false} axisLine={false} stroke="whitesmoke" hide />
        <Tooltip
          contentStyle={{
            backgroundColor: "transparent",
            border: "none",
            color: "#c770f0",
          }}
          wrapperStyle={{ top: 50 }}
        />
        <Line
          type="monotone"
          dataKey="price"
          stroke="#cc3600"
          dot={false}
          strokeWidth={4}
          tension={0.5}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default MoonPriceChart;
