import React, { useState, useEffect } from "react";
import axios from "axios";

// converts number to string representation with K and M.
// toFixed(d) returns a string that has exactly 'd' digits
// after the decimal place, rounding if necessary.
const numFormatter = function (num) {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num >= 1000000 && num < 1000000000) {
    return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
  } else if (num <= 999) {
    return num; // if value < 1000, nothing to do
  } else if (num > 1000000000) {
    return (num / 1000000000).toFixed(1) + "B";
  }
};

function SecondaryStats() {
  const [price, setPrice] = useState(0);
  const [burnBalance, setBurnBalance] = useState(0);

  const priceUrl =
    "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=moon";

  const burnUrl =
    "https://api-nova.arbiscan.io/api?module=account&action=tokenbalance&contractaddress=0x0057Ac2d777797d31CD3f8f13bF5e927571D6Ad0&address=0x000000000000000000000000000000000000dead&tag=latest";

  useEffect(() => {
    axios
      .get(priceUrl)
      .then((res) => {
        setPrice(Math.round(res.data[0].current_price * 1000) / 1000);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    axios
      .get(burnUrl)
      .then((res) => {
        setBurnBalance(parseInt(res.data.result) / 1000000000000000000);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          color: "whitesmoke",
        }}
      >
        <div className="info-head-container">
          <div>Price</div>
          <div className="info-head">${price}</div>
        </div>
        <div className="info-head-container">
          <div>MarketCap</div>
          <div className="info-head">
            ${numFormatter(Math.round((82279600 - burnBalance) * price))}
          </div>
        </div>
        <div className="info-head-container">
          <div>Max Supply</div>
          <div className="info-head">
            {numFormatter(Math.round(82279600 - burnBalance))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecondaryStats;
