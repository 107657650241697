import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import TradeCard from "./TradeCard";
import Particle from "../Particle";
import kraken from "../../Assets/Projects/kraken.png";
import cryptocom from "../../Assets/Projects/cryptocom-2.png";
import sushi from "../../Assets/Projects/sushi-2.png";
import camelot from "../../Assets/Projects/camelot.jpeg";

function Trades() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          <strong className="main-name">Buy Moons</strong> Here
        </h1>
        <p style={{ color: "whitesmoke" }}>
          Moons are available on both Arbitrum Nova and Arbitrum One
        </p>
        <Row style={{ paddingTop: "30px", color: "whitesmoke" }}>
          <div className="heading-2">Centralized Exchanges</div>
        </Row>
        <Row
          style={{
            justifyContent: "center",
            paddingBottom: "10px",
          }}
        >
          <Col style={{ width: "50%" }} md={5} className="project-card">
            <TradeCard
              title="Kraken"
              link="https://kraken.com"
              chain="(Arbitrum Nova)"
              imgPath={kraken}
            />
          </Col>
          <Col style={{ width: "50%" }} md={5} className="project-card">
            <TradeCard
              title="Crypto.com"
              link="https://crypto.com"
              chain="(Arbitrum Nova)"
              imgPath={cryptocom}
            />
          </Col>
          <Row style={{ color: "whitesmoke" }}>
            <div className="heading-2">Decentralized Exchanges</div>
          </Row>
          <Col style={{ width: "50%" }} md={5} className="project-card">
            <TradeCard
              title="Sushiswap"
              link="https://www.sushi.com/swap?chainId=42170"
              chain="(Arbitrum Nova)"
              imgPath={sushi}
            />
          </Col>
          <Col style={{ width: "50%" }} md={5} className="project-card">
            <TradeCard
              title="Camelot"
              link="https://app.camelot.exchange/"
              chain="(Arbitrum One)"
              imgPath={camelot}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Trades;
