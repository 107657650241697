import React from "react";
import Card from "react-bootstrap/Card";

function GuideCard(props) {
  return (
    <Card
      className="project-card-view"
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Card.Body className="d-flex flex-column justify-content-center">
        <Card.Title>
          <a
            className="a-exchange"
            href={props.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.title}
          </a>
        </Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default GuideCard;
