import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import MaterialTable from "material-table";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import {
  Search,
  ChevronLeft,
  ChevronRight,
  Clear,
  ArrowUpward,
} from "@material-ui/icons";

export default function Balances() {
  const [balances, setBalances] = useState([]);
  const isBalancesEmpty = balances.length === 0;

  const theme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  useEffect(() => {
    if (isBalancesEmpty) {
      fetch("/api/balances")
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error("Network response was not ok.");
        })
        .then((jsonRes) => {
          setBalances(jsonRes.balanceData);
          console.log(balances);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [isBalancesEmpty]);

  const columns = [
    { title: "User", field: "User", width: "20%" },
    {
      title: "Moons",
      field: "Balance",
      render: (rowData) => Math.round(rowData.Balance).toLocaleString(),
      width: "80%",
    },
  ];

  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
            <span className="main-name">Moon</span> Holders
          </h1>
          <ThemeProvider theme={theme}>
            <MaterialTable
              title="Balances"
              columns={columns}
              data={balances}
              options={{
                pageSize: 10,
                pagination: false, // Hide pagination controls and text
                search: true,
                sorting: true,
                showTitle: false,
                showFirstLastPageButtons: false,
                TableLayout: "fixed",
              }}
              icons={{
                NextPage: ChevronRight,
                PreviousPage: ChevronLeft,
                Search: Search,
                Clear: Clear,
                ResetSearch: Clear,
                SortArrow: ArrowUpward,
              }}
            />
          </ThemeProvider>
        </Row>
      </Container>
    </Container>
  );
}
