import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";

function Shop() {
  return (
    <Container fluid className="project-section" style={{ minHeight: "100vh" }}>
      <Particle />
      <Container>
        <h1 className="project-heading text-center mb-5">
          Welcome to the <strong className="main-name">CCMOON</strong> Merch
          Shop
        </h1>
        <Row className="justify-content-center">
          <Col md={12}>
            <div
              style={{
                position: "relative",
                overflow: "hidden",
                width: "100%",
                height: "80vh", // Adjust height for visibility and scrolling
              }}
            >
              <iframe
                title="CCMOON Merch Shop"
                src="https://ccmoons-merch.myspreadshop.com/"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  border: "none", // Remove border for a cleaner look
                  overflow: "auto", // Ensure scrolling within the iframe
                }}
                allowFullScreen
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Shop;
