import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Timeline from "./Timeline";

function Home2() {
  return (
    <Container fluid className="home-section" id="about">
      <Container>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              Created by <span className="purple"> Reddit</span>
            </h1>
            <h1>
              Owned by the
              <span className="main-name"> Community</span>
            </h1>
            <p>
              Reddit Admins created Moons in May 2020, but cut ties in October
              2023.
            </p>
            <p>
              Moons are now an <strong>independent project</strong> run by the
              community.
            </p>
          </Col>
          <Timeline />
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
