import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

const numFormatter = function (num) {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + "K";
  } else if (num >= 1000000 && num < 1000000000) {
    return (num / 1000000).toFixed(1) + "M";
  } else if (num <= 999) {
    return num;
  } else if (num > 1000000000) {
    return (num / 1000000000).toFixed(1) + "B";
  }
};

const CumulativeBalanceChart = () => {
  const [data, setData] = useState([]);
  const [totalBurned, setTotalBurned] = useState({ nova: 0, one: 0 });

  const getCumulativeBalanceHistory = async (
    address,
    contractAddressNova,
    contractAddressOne
  ) => {
    const pastDays = 90;
    const thirtyDaysAgoTimestamp =
      Math.floor(Date.now() / 1000) - pastDays * 24 * 60 * 60;

    const fetchTransactions = async (apiBaseUrl, contractAddress, apiKey) => {
      try {
        const blockNumberRes = await axios.get(
          `${apiBaseUrl}/api/?module=block&action=getblocknobytime&timestamp=${thirtyDaysAgoTimestamp}&closest=before&apikey=${apiKey}`
        );
        const blockNumber = blockNumberRes.data.result;

        const transactionsRes = await axios.get(
          `${apiBaseUrl}/api/?module=account&action=tokentx&address=${address}&startblock=${blockNumber}&endblock=latest&contractaddress=${contractAddress}&apikey=${apiKey}`
        );

        return transactionsRes.data.result.filter(
          (tx) => tx.timeStamp >= thirtyDaysAgoTimestamp
        );
      } catch (error) {
        console.error(`Error fetching transactions from ${apiBaseUrl}:`, error);
        throw error;
      }
    };

    try {
      const [novaTransactions, oneTransactions] = await Promise.all([
        fetchTransactions(
          "https://api-nova.arbiscan.io",
          contractAddressNova,
          "CTCMS298Z2JNI3JSGHFXTAGYCW86A7EFWU"
        ),
        fetchTransactions(
          "https://api.arbiscan.io",
          contractAddressOne,
          "Z3XPSC6BPQ12N18Q1N7JD65CXBMDZU1ZZI"
        ),
      ]);

      // Create daily buckets for each chain
      const dailyBucketsNova = {};
      const dailyBucketsOne = {};
      let runningTotalNova = 0;
      let runningTotalOne = 0;

      // Initialize all dates in the past 90 days
      for (let i = 0; i < pastDays; i++) {
        const date = new Date(Date.now() - i * 24 * 60 * 60 * 1000);
        const formattedDate = `${
          date.getMonth() + 1
        }/${date.getDate()}/${date.getFullYear()}`;
        dailyBucketsNova[formattedDate] = 0;
        dailyBucketsOne[formattedDate] = 0;
      }

      // Process Nova transactions
      novaTransactions.forEach((tx) => {
        const date = new Date(tx.timeStamp * 1000);
        const formattedDate = `${
          date.getMonth() + 1
        }/${date.getDate()}/${date.getFullYear()}`;
        const burnAmount = parseFloat(tx.value) / 1e18;

        if (dailyBucketsNova.hasOwnProperty(formattedDate)) {
          dailyBucketsNova[formattedDate] += burnAmount;
        }
      });

      // Process One transactions
      oneTransactions.forEach((tx) => {
        const date = new Date(tx.timeStamp * 1000);
        const formattedDate = `${
          date.getMonth() + 1
        }/${date.getDate()}/${date.getFullYear()}`;
        const burnAmount = parseFloat(tx.value) / 1e18;

        if (dailyBucketsOne.hasOwnProperty(formattedDate)) {
          dailyBucketsOne[formattedDate] += burnAmount;
        }
      });

      // Create cumulative balance history
      const balanceHistory = [];
      const dates = Object.keys(dailyBucketsNova).sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA - dateB;
      });

      dates.forEach((date) => {
        runningTotalNova += dailyBucketsNova[date];
        runningTotalOne += dailyBucketsOne[date];
        balanceHistory.push({
          date,
          nova: runningTotalNova,
          one: runningTotalOne,
          total: runningTotalNova + runningTotalOne,
        });
      });

      setData(balanceHistory);
      setTotalBurned({
        nova: runningTotalNova,
        one: runningTotalOne,
      });
    } catch (error) {
      console.error("Error in getCumulativeBalanceHistory:", error);
    }
  };

  useEffect(() => {
    const address = "0x000000000000000000000000000000000000dead";
    const contractAddressNova = "0x0057Ac2d777797d31CD3f8f13bF5e927571D6Ad0";
    const contractAddressOne = "0x24404dc041d74cd03cfe28855f555559390c931b";
    getCumulativeBalanceHistory(
      address,
      contractAddressNova,
      contractAddressOne
    );
  }, []);

  const formatXAxis = (tickItem) => {
    const dateParts = tickItem.split("/");
    const month = dateParts[0];
    const day = dateParts[1];
    const year = dateParts[2];
    return `${month}/${day}/${year}`;
  };

  return (
    <div className="w-full">
      <h5
        style={{ textAlign: "center", color: "whitesmoke", paddingTop: "25px" }}
      >
        90-Day Moon Burn (Total:{" "}
        {Math.round(totalBurned.nova + totalBurned.one).toLocaleString()})
      </h5>
      <ResponsiveContainer width="100%" aspect={3.5}>
        <AreaChart data={data}>
          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={false}
            stroke="whitesmoke"
            tickFormatter={formatXAxis}
            hide
          />
          <YAxis
            tickFormatter={numFormatter}
            axisLine={false}
            tickLine={false}
            stroke="whitesmoke"
            hide
          />
          <Tooltip
            formatter={(value) => numFormatter(value)}
            contentStyle={{
              backgroundColor: "transparent",
              border: "none",
              color: "#c770f0",
            }}
            wrapperStyle={{ top: 50 }}
          />
          <Legend
            align="center"
            verticalAlign="top"
            height={36}
            formatter={(value) => {
              const formattedValue =
                value.charAt(0).toUpperCase() + value.slice(1);
              return <span style={{ color: "#c770f0" }}>{formattedValue}</span>;
            }}
          />
          <Area
            type="monotone"
            dataKey="nova"
            stroke="#cc3600"
            fill="#cc3600"
            fillOpacity={0.2}
            strokeWidth={2}
            name="Arb Nova"
          />
          <Area
            type="monotone"
            dataKey="one"
            stroke="#4a90e2"
            fill="#4a90e2"
            fillOpacity={0.2}
            strokeWidth={2}
            name="Arb One"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CumulativeBalanceChart;
