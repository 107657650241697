import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import rccLogo from "../../Assets/rcc_logo.png";
import moonLogoBigger from "../../Assets/logo_bigger.png";
import Particle from "../Particle";
import Home2 from "./Home2";
import Home15 from "./Home15";
import Type from "./Type";
import MainStats from "./MainStats";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                <strong className="main-name"> MOONS</strong>: The official
                <span className="purple"> governance </span> token of the
                r/CryptoCurrency community
              </h1>
            </Col>
            <Col
              md={5}
              className="home-header"
              style={{
                paddingBottom: 20,
                display: "flex",
                justifyContent: "Center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={rccLogo} height={100} width={100} />
                <p style={{ fontSize: 60 }}>+</p>
                <img src={moonLogoBigger} height={100} width={100} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="home-header">
              <h2 style={{ paddingBottom: 15 }} className="heading-2">
                Leading cryptocurrency companies
                <strong className="purple"> purchase</strong> and{" "}
                <strong className="purple">burn </strong>Moons to advertise on
                r/CryptoCurrency.
                <br />
                <br />
                <div
                  style={{
                    paddingBottom: 50,
                    display: "flex",
                    alignItems: "baseline",
                    fontSize: "0.85em",
                  }}
                >
                  <div>🔥</div>
                  <div>
                    <Type />
                  </div>
                </div>
              </h2>
            </Col>
          </Row>
          <MainStats />
        </Container>
      </Container>
      <Home15 />
      <Home2 />
    </section>
  );
}

export default Home;
